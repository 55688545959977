export const BASIC_CARS = [
  {
    id: "car-1",
    name: "Renault CLIO",
    gear: "AUTOMAT",
    drive: "FWD",
    fuel: "PB + LPG",
    hp: 100,
    year: "2022",
    price: 140.0,
    img: "/images/reservation/clio.jpeg",
  },
  {
    id: "car-2",
    name: "Dacia SANDERO",
    gear: "MANUAL",
    drive: "FWD",
    fuel: "PB+LPG",
    hp: 90,
    year: "2020",
    price: 130.0,
    img: "/images/reservation/sandero.jpg",
  },
  {
    id: "car-3",
    name: "Opel ASTRA",
    gear: "MANUAL",
    drive: "FWD",
    fuel: "ON",
    hp: 140,
    year: "2011",
    price: 150.0,
    img: "/images/reservation/astra.jpg",
  },
  {
    id: "car-4",
    name: "Dacia DUSTER",
    gear: "MANUAL",
    drive: "FWD",
    fuel: "PB+LPG",
    hp: 90,
    year: "2020",
    price: 160.0,
    img: "/images/reservation/duster.jpg",
  },
  {
    id: "car-5",
    name: "Citroën C4 GRAND Picasso",
    gear: "MANUAL",
    drive: "FWD",
    fuel: "ON",
    hp: 150,
    year: "2008",
    price: 160.0,
    img: "/images/reservation/picasso.jpg",
  },
  {
    id: "car-6",
    name: "Renault MEGANE",
    gear: "AUTOMAT",
    drive: "FWD",
    fuel: "PB",
    hp: 140,
    year: "2021",
    price: 160.0,
    img: "/images/reservation/megane.jpg",
  },
  {
    id: "car-7",
    name: "Skoda KODIAQ",
    gear: "AUTOMAT",
    drive: "AWD",
    fuel: "ON",
    hp: 240,
    year: "2020",
    price: 490.0,
    img: "/images/reservation/kodiaq.jpg",
  },
  {
    id: "car-8",
    name: "Jaguar XE",
    gear: "AUTOMAT",
    drive: "AWD",
    fuel: "PB",
    hp: 250,
    year: "2020",
    price: 690.0,
    img: "/images/reservation/jaguar.jpg",
  },
  {
    id: "car-9",
    name: "Renault TRAFIC",
    gear: "MANUAL",
    drive: "FWD",
    fuel: "ON+AB",
    hp: 150,
    year: "2024",
    price: 230.0,
    img: "/images/reservation/traffic.jpg",
  },
  {
    id: "car-10",
    name: "Opel VIVARO H1L2",
    gear: "MANUAL",
    drive: "FWD",
    fuel: "ON+AB",
    hp: 121,
    year: "2019",
    price: 200.0,
    img: "/images/reservation/vivaro.jpg",
  },
  {
    id: "car-11",
    name: "Przyczepa HAKPOL",
    gear: "-",
    drive: "JEDNOOSIOWA | 200 x 110 x 125",
    fuel: "-",
    hp: null,
    year: "2020",
    price: 50.0,
    img: "/images/reservation/hakpol.jpg",
  },
];

export const RESERVATION_CONTENT = [
  "MAWEX wypożyczalnia",
  "Firma 'MAWEX wypożyczalnia' oferuje wynajem pojazdów osobowych krótko, średnio i długoterminowy.",
  "Adres: Ciepła 11, 32-800 Brzesko",
  "Telefon: 793 272 000",
  "Zapraszamy do zapoznania się z naszą ofertą.",
];
