export const CERTYFICATIONS = [
  {
    id: 1,
    name: "certyfikat-1.jpg",
    path: "/images/cert-page/certyfikat-1.jpg",
  },
  {
    id: 2,
    name: "certyfikat-2.jpg",
    path: "/images/cert-page/certyfikat-2.jpg",
  },
  {
    id: 3,
    name: "certyfikat-13jpg",
    path: "/images/cert-page/certyfikat-3.jpg",
  },
  {
    id: 4,
    name: "certyfikat-4.jpg",
    path: "/images/cert-page/certyfikat-4.jpg",
  },
  {
    id: 5,
    name: "certyfikat-5.jpg",
    path: "/images/cert-page/certyfikat-5.jpg",
  },
];
